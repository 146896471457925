<template>
  <layout>
    <template v-slot:header-title>
      {{ pageTitle }}
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__content">
            <div class="main__content-headline">
              <h5>Basic settings</h5>
            </div>

            <div>
              <div class="main__content-btns">
                <button class="btn btn-big btn-fw" @click.prevent="save">
                  <span><i>Save build</i></span>
                </button>
              </div>
              <ul class="nav nav-tabs list-unstyled">
                <li
                  v-for="language in languages"
                  :key="language.prefix"
                  :class="languageTabClass(language)"
                >
                  <a @click.prevent="switchLanguage(language)">
                    {{ language.title }}
                  </a>
                </li>
              </ul>

              <div
                v-for="language in languages"
                v-show="showLanguageTab(language)"
                :key="language.prefix"
              >
                <div class="main__content tab-pane">
                  <div class="main__content-in">
                    <f-input-text v-model="build.ms_id" title="ID (MS D)" />
                    <f-input-text
                      v-if="build.id !== null"
                      v-model="build.id"
                      :disabled="true"
                      title="ID (CRM)"
                    />
                    <f-input-checkbox v-model="build.blocked" title="Blocked" />
                    <f-input-checkbox
                      v-model="build.is_limited"
                      title="Limited Edition"
                    />

                    <f-select
                      v-model="build.category"
                      :options="$store.state.build.categories"
                      title="Type"
                    />

                    <f-input-checkbox
                      v-if="build.category.id === 1"
                      v-model="build.compare_available"
                      title="Available for compare"
                    />

                    <f-input-checkbox
                      v-if="build.category.id === 2"
                      v-model="build.multiple"
                      title="Multiple add to bag"
                    />
                    <f-input-checkbox
                      v-if="build.category.id === 2"
                      v-model="build.as_extra"
                      title="Available as extra option"
                    />

                    <f-select
                      v-show="build.category.id === 2"
                      v-model="build.compatibility"
                      :multiple="true"
                      :options="bikeModels"
                      title="Compatibility"
                    />

                    <f-writeable-select
                      v-model="modelCategory"
                      :options="modelCategories"
                      label="title"
                      title="Model/Category"
                      @onSelectExist="handleExistModelCategory($event)"
                    />

                    <f-writeable-select
                      v-model="buildSubCategory"
                      :options="buildSubCategories"
                      label="title"
                      title="Build/Sub-category"
                      @onSelectExist="handleExistSubCategory($event)"
                    />

                    <f-input-checkbox
                      v-model="build.pinned"
                      title="Pinned"
                    />

                    <f-input-checkbox
                      v-if="build.category.id === 1"
                      v-model="build.dashboard"
                      title="Dashboard"
                    />

                    <f-select
                      v-show="build.category.id === 1"
                      v-model="build.bike_type"
                      :options="bikeTypes"
                      title="Bike type"
                    />

                    <f-select
                      v-show="build.category.id === 1"
                      v-model="build.filter_category"
                      :options="filterCategories"
                      title="Category"
                    />

                    <f-select
                      v-show="build.category.id === 1"
                      v-model="build.build_experience"
                      :options="experiences"
                      title="Experience"
                    />

                    <f-select
                      v-show="build.category.id === 1"
                      v-model="build.build_wheel"
                      :options="wheelSizes"
                      title="Wheel size"
                    />

                    <f-select
                      v-show="build.category.id === 1"
                      v-model="build.build_motor"
                      :options="motorTypes"
                      title="Motor type"
                    />

                    <f-select
                      v-show="build.category.id === 1"
                      v-model="build.build_suspension_travel"
                      :options="suspensionTravel"
                      title="Suspension travel"
                    />

                    <f-select v-model="size" :options="sizes" title="Size" />

                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Delivery date:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <date-picker
                            v-model="deliveryDate"
                            format="MM.YYYY"
                            type="date"
                            valueType="YYYY-MM-DD"
                          />
                        </div>
                      </div>
                    </div>

                    <f-input-text
                      v-model="build.ms_title"
                      title="Bike/Part title (from MS D)"
                    />

                    <f-input-text
                      v-model="
                        build[fieldWithPrefix(language, 'web_bike_title')]
                      "
                      title="Bike/Part title (for web)"
                    />

                    <f-input-text
                      v-model="
                        build[fieldWithPrefix(language, 'app_bike_title')]
                      "
                      title="Bike/Part title (for app)"
                    />

                    <f-textarea
                      v-model="build.ms_description"
                      title="Description (from MS D)"
                    />

                    <f-textarea
                      v-model="build[fieldWithPrefix(language, 'description')]"
                      title="Description (for web)"
                    />

                    <f-textarea
                      v-model="
                        build[fieldWithPrefix(language, 'short_description')]
                      "
                      title="Short Description (ex. for extra)"
                    />

                    <f-input-text
                      :disabled="true"
                      :value="colorName"
                      title="Color name (from MS D)"
                    />

                    <f-select
                      v-model="color"
                      :options="$store.state.colors.list"
                      label="ms_title"
                      title="Colorname (for web & app)"
                    />

                    <f-input-text
                      :disabled="true"
                      :value="colorPrice"
                      title="Color price, €"
                      type="number"
                    />

                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Custom:</span>
                      </div>
                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__checkbox">
                          <input
                            id="color-custom"
                            :checked="isColorCustom"
                            disabled="disabled"
                            type="checkbox"
                          />
                          <label for="color-custom"></label>
                        </div>
                      </div>
                    </div>

                    <f-input-text
                      :disabled="true"
                      :value="colorGroup"
                      title="Color group"
                    />

                    <f-select v-model="brand" :options="brands" title="Brand" />

                    <f-input-text v-model="build.ean" title="EAN" />

                    <f-input-text
                      v-model="build.vendor_code"
                      title="Vendor code"
                    />

                    <f-input-text
                      v-model="build.weight"
                      title="Weight, g"
                      type="number"
                    />

                    <f-input-text
                      v-model="build.length"
                      title="Length, mm"
                      type="number"
                    />

                    <f-input-text
                      v-model="build.height"
                      title="Height mm"
                      type="number"
                    />

                    <f-input-text
                      v-model="build.width"
                      title="Width mm"
                      type="number"
                    />

                    <div
                      v-if="build.id !== null"
                      class="main__content-item main__content-item_center"
                    >
                      <div class="main__content-item_l">
                        <span>Created:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group__text">
                          {{ buildCreatedAt }}
                          by
                          <router-link :to="creatorLink">
                            {{ build.creator.name }}
                          </router-link>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="false"
                      class="main__content-item main__content-item_center"
                    >
                      <div class="main__content-item_l">
                        <span>Edited:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group__text">
                          14.06.2019 16:32:52 by Anastasia test test test test
                          test test test test test test
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Cost & stock</h5>
            </div>
            <div class="main__content-in">
              <f-input-text
                v-model="build.cost"
                title="Price Website, €"
                type="number"
              />

              <f-input-text
                v-model="build.cost"
                title="Cost, €"
                type="number"
              />

              <f-input-text
                v-model="build.profit"
                title="Margin, €"
                type="number"
              />

              <f-input-text
                v-model="build.profit_percent"
                :max="100"
                title="Margin, %"
                type="number"
              />
              <hr class="wcs" />
              <div class="main__content-table">
                <div class="main__content-table_thead">
                  <div class="tr">
                    <div class="th"></div>
                    <div class="th">VAT (%)</div>
                    <div class="th">Price, €</div>
                    <div class="th">Exclusive presale price, €</div>
                    <div class="th">Reservation payment, €</div>
                  </div>
                </div>

                <div class="main__content-table__tbody">
                  <div class="tr">
                    <div class="td"><strong>Tax base:</strong></div>
                    <div class="td">
                      <div class="inp-group">
                        <div class="td">
                          <div class="inp-group">0%</div>
                        </div>
                      </div>
                    </div>
                    <div class="td">
                      <div class="inp-group">
                        <f-small-text-input
                          v-model="taxBase"
                          title="Price"
                          type="number"
                        />
                      </div>
                    </div>
                    <div class="td">
                      <div class="inp-group">
                        <f-small-text-input
                          v-model="exTaxBase"
                          title="Exclusive presale price"
                          type="number"
                        />
                      </div>
                    </div>
                    <div class="td">
                      <div class="inp-group">
                        <f-small-text-input
                          v-model="resTaxBase"
                          title="Reservation payment"
                          type="number"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    v-for="country in countryList"
                    :key="country.code"
                    class="tr big"
                  >
                    <div class="td">{{ country.name }}</div>
                    <div class="td">
                      <div class="inp-group">{{ country.vat }}%</div>
                    </div>
                    <div class="td">
                      <div class="inp-group">
                        {{ $crConverter(mainPrice(country.vat)) }}
                      </div>
                    </div>
                    <div class="td">
                      <div class="inp-group">
                        {{ $crConverter(exPrice(country.vat)) }}
                      </div>
                    </div>
                    <div class="td">
                      <div class="inp-group">
                        {{ $crConverter(resPrice(country.vat)) }}
                      </div>
                    </div>
                  </div>

                  <div class="show-more">
                    <span
                      class="show-more-countries"
                      @click="showMoreCountries"
                    >
                      {{ lessOrMore }}
                    </span>
                  </div>
                  <hr class="wcs" />
                </div>
              </div>
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Presale, start:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <date-picker
                      v-model="build.presale_start_at"
                      :format="'YYYY/MM/DD'"
                      type="date"
                      valueType="YYYY-MM-DD"
                    />
                  </div>
                </div>
              </div>
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Presale, finish:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <date-picker
                      v-model="build.presale_end_at"
                      :format="'YYYY/MM/DD'"
                      type="date"
                      valueType="YYYY-MM-DD"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="build.id !== null"
                class="main__content-item main__content-item_center"
              >
                <div class="main__content-item_l">
                  <span>Stock:</span>
                </div>

                <div class="main__content-item_r group-el">
                  <div class="inp-group__text">{{ build.stock }}</div>
                  <div class="inp-group__dropdown inp-group__xl">
                    <v-select
                      v-model="stockType"
                      :clearable="false"
                      :options="stockOptions"
                      label="title"
                    />
                  </div>
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="build.changeStock"
                      :min="0"
                      title="Stock"
                      type="number"
                    />
                  </div>
                  <button class="btn btn-green" @click="changeStock()">
                    Save
                  </button>
                </div>
              </div>
              <div
                v-if="build.id === null"
                class="main__content-item main__content-item_center"
              >
                <div class="main__content-item_l">
                  <span>Stock:</span>
                </div>

                <div class="main__content-item_r group-el">
                  <div class="inp-group__text">{{ build.stock }}</div>
                  <div class="inp-group inp-group__xl">
                    <input v-model="build.stock" title="Stock" type="number" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Media content</h5>
            </div>

            <div class="main__content-in">
              <div v-if="build.media.ms_cover" class="main__content-item">
                <div class="main__content-item_l">
                  <span>Cover (from MS D):</span>
                </div>

                <div class="main__content-item_r">
                  <div v-if="build.media.ms_cover" class="uploaded__img">
                    <img :alt="build.title" :src="build.media.ms_cover" />
                  </div>
                </div>
              </div>

              <div class="main__content-item">
                <div class="main__content-item_l">
                  <span>Preview image:</span>
                </div>

                <div class="main__content-item_r">
                  <media-uploader
                    :key="`main-${nonce}`"
                    :auto-processing="true"
                    :media="previewImages"
                    :multiple="false"
                    :per-file-upload="false"
                    :processing-u-r-l="dropzoneSettings.url"
                    field-name="file"
                    footer-text="Drag&drop or <u>click here</u> to upload images (jpg, 1500x1000 up to 15MB)"
                    type="image"
                    @onRemove="removeFromMedia($event, 'addMediaPreview', 'preview')"
                    @onSuccessResponse="addToMedia($event, 'addMediaPreview')"
                  />
                </div>
              </div>

              <div class="main__content-item">
                <div class="main__content-item_l">
                  <span>Gallery:</span>
                </div>

                <div class="main__content-item_r">
                  <media-uploader
                    :key="`main-${nonce}`"
                    :auto-processing="true"
                    :media="galleryImages"
                    :multiple="true"
                    :per-file-upload="true"
                    :processing-u-r-l="dropzoneSettingsMultiple.url"
                    field-name="file"
                    footer-text="Drag&drop or <u>click here</u> to upload images (jpg, 3000x2000 up to 15MB)"
                    type="image"
                    @onRemove="removeFromMedia($event, 'addMediaImages', 'images')"
                    @onSuccessResponse="addToMedia($event, 'addMediaImages')"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="showSpecList" class="main__content">
            <div class="main__content-headline">
              <h5>Spec list</h5>
            </div>

            <div class="main__content-in">
              <ul class="nav nav-tabs list-unstyled">
                <li
                  v-for="language in languages"
                  :key="language.prefix"
                  :class="
                    language.title === currentLanguage.title ? 'active' : ''
                  "
                >
                  <a @click.prevent="switchLanguage(language)">
                    {{ language.title }}
                  </a>
                </li>
              </ul>

              <div
                v-for="language in languages"
                v-show="language.title === currentLanguage.title"
                :key="language.prefix"
              >
                <div class="main__content">
                  <div class="main__content-in">
                    <div v-for="spec in $store.state.specs.list" :key="spec.id">
                      <div
                        v-if="build.specs[spec.id]"
                        class="main__content-item main__content-item_center"
                      >
                        <div class="main__content-item_l align-self-start">
                          <strong>{{ spec.title }}:</strong>
                        </div>
                        <div class="main__content-item_r">
                          <div class="inp-group inp-group__xl mb-2">
                            <!--suppress HtmlFormInputWithoutLabel -->
                            <input
                              v-model="build.specs[spec.id].title"
                              :title="`${spec.title} title`"
                              type="text"
                            />
                          </div>
                          <div class="inp-group inp-group__xl">
                            <!--suppress HtmlFormInputWithoutLabel -->
                            <input
                              v-model="build.specs[spec.id].description"
                              :title="`${spec.title} description`"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="build.specs[spec.id]"
                        class="main__content-item main__content-item_center"
                      >
                        <div class="main__content-item_l">
                          <span>Highlight:</span>
                        </div>
                        <div class="main__content-item_r">
                          <div class="inp-group inp-group__checkbox">
                            <input
                              :id="`test-${spec.id}`"
                              v-model="build.specs[spec.id].highlight"
                              type="checkbox"
                            />
                            <label :for="`test-${spec.id}`"></label>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Meta</h5>
            </div>

            <ul class="nav nav-tabs list-unstyled">
              <li
                v-for="language in languages"
                :key="language.prefix"
                :class="
                  language.title === currentLanguage.title ? 'active' : ''
                "
              >
                <a @click.prevent="switchLanguage(language)">
                  {{ language.title }}
                </a>
              </li>
            </ul>

            <div
              v-for="language in languages"
              v-show="language.title === currentLanguage.title"
              :key="language.prefix"
            >
              <div class="main__content-in">
                <f-input-text v-model="build.meta.title" title="Title" />
                <f-textarea
                  v-model="build.meta.description"
                  title="Description"
                />
                <f-input-text v-model="build.meta.tags" title="Keywords" />

                <div class="main__content-item main__content-item_center">
                  <div class="main__content-item_l">
                    <span>Slug:</span>
                  </div>

                  <div class="main__content-item_r">
                    <div class="inp-group inp-group__xl">
                      <span class="inp-group__plug"
                      >https://forestal.com/en/products/</span
                      >
                      <input
                        v-model="build.slug"
                        title="Meta slug"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="main__content-item">
                  <div class="main__content-item_l">
                    <span>Image:</span>
                  </div>

                  <div class="main__content-item_r">
                    <media-uploader
                      :key="`main-${nonce}`"
                      :auto-processing="true"
                      :media="metaImage"
                      :multiple="false"
                      :per-file-upload="false"
                      :processing-u-r-l="dropzoneSettings.url"
                      field-name="file"
                      footer-text="Drag&drop or <u>click here</u> to upload images (jpg, 1500x1000 up to 15MB)"
                      type="image"
                      @onRemove="removeFromMedia($event, 'setMetaImage', 'meta')"
                      @onSuccessResponse="addToMedia($event, 'setMetaImage')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <included-in-delivery
            v-if="build.category.id === 1"
            :model="selectedModel"
          />

          <feed
            v-if="build && build.id"
            :subject-id="build ? build.id : null"
            subject="build"
          />

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click.prevent="save">
              <span><i>Save build</i></span>
            </button>
            <button
              v-if="build.id !== null"
              class="btn btn-big btn-fw"
              @click.prevent="clone"
            >
              <span><i>Clone build</i></span>
            </button>
            <button class="btn btn-big btn-fw" @click="back">
              <span><i>Cancel</i></span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import DatePicker from "vue2-datepicker";
import Dropzone from "./forms/inputs/Dropzone";
import vSelect from "vue-select";
import Text from "@/components/inputs/Text";
import CheckBox from "@/components/inputs/CheckBox";
import TextArea from "@/components/inputs/TextArea";
import SelectWithSearch from "@/components/inputs/SelectWithSearch";
import Select from "@/components/inputs/Select";
import IncludedInDelivery from "@/components/partials/builds/IncludedInDelivery";
import SmallText from "@/components/inputs/SmallText";
import Feed from "@/components/partials/orders/OrderFeed";
import Build from "@/store/models/Build";
import MediaUploader from "@/components/inputs/MediaUploader.vue";
import { API_ENDPOINT } from "@/utils/api";
import { mapGetters } from "vuex";

export default {
  name: "CRMEditBuild",
  components: {
    MediaUploader,
    layout: CRMLayout,
    vSelect,
    DatePicker,
    Dropzone,
    "f-input-text": Text,
    "f-input-checkbox": CheckBox,
    "f-textarea": TextArea,
    "f-writeable-select": SelectWithSearch,
    "f-select": Select,
    "f-small-text-input": SmallText,
    "included-in-delivery": IncludedInDelivery,
    feed: Feed,
  },
  data() {
    return {
      nonce: 1,
      selectedColor: null,
      stockOptions: [
        {
          id: "plus",
          title: "increase by",
        },
        {
          id: "minus",
          title: "decrease by",
        },
      ],
      stockType: {
        id: "plus",
        title: "increase by",
      },
      currentTag: "",
      currentLanguage: {
        prefix: "en",
        title: "EN",
      },
      languages: [
        {
          prefix: "en",
          title: "EN",
        },
        {
          prefix: "es_",
          title: "ES",
        },
        {
          prefix: "de_",
          title: "DE",
        },
        {
          prefix: "fr_",
          title: "FR",
        },
        {
          title: "RU",
          prefix: "ru_",
        },
      ],
      reserved: [],
      reservedNumber: "",
      oldReservedNumber: "",
      showBuildTitlePopup: false,
      showColorPopup: false,
      showColorGroupPopup: false,
      eu_countries: [
        {
          name: "Austria",
          code: "AT",
          vat: 20,
        },
        {
          name: "Belgium",
          code: "BE",
          vat: 21,
        },
        {
          name: "Bulgaria",
          code: "BG",
          vat: 20,
        },
        {
          name: "Croatia",
          code: "HR",
          vat: 25,
        },
        {
          name: "Cyprus",
          code: "CY",
          vat: 19,
        },
        {
          name: "Czech Republic",
          code: "CZ",
          vat: 21,
        },
        {
          name: "Denmark",
          code: "DK",
          vat: 25,
        },
        {
          name: "Estonia",
          code: "EE",
          vat: 20,
        },
        {
          name: "Finland",
          code: "FI",
          vat: 24,
        },
        {
          name: "France",
          code: "FR",
          vat: 20,
        },
        {
          name: "Germany",
          code: "DE",
          vat: 19,
        },
        {
          name: "Greece",
          code: "GR",
          vat: 24,
        },
        {
          name: "Hungary",
          code: "HU",
          vat: 27,
        },
        {
          name: "Ireland",
          code: "IE",
          vat: 23,
        },
        {
          name: "Italy",
          code: "IT",
          vat: 22,
        },
        {
          name: "Latvia",
          code: "LV",
          vat: 21,
        },
        {
          name: "Lithuania",
          code: "LT",
          vat: 21,
        },
        {
          name: "Luxembourg",
          code: "LU",
          vat: 17,
        },
        {
          name: "Malta",
          code: "MT",
          vat: 18,
        },
        {
          name: "Netherlands",
          code: "NL",
          vat: 21,
        },
        {
          name: "Poland",
          code: "PL",
          vat: 23,
        },
        {
          name: "Portugal",
          code: "PT",
          vat: 23,
        },
        {
          name: "Romania",
          code: "RO",
          vat: 19,
        },
        {
          name: "Slovakia",
          code: "SK",
          vat: 20,
        },
        {
          name: "Slovenia",
          code: "SI",
          vat: 22,
        },
        {
          name: "Spain",
          code: "ES",
          vat: 21,
        },
        {
          name: "Sweden",
          code: "SE",
          vat: 25,
        },
      ],
      shortCountryList: true,
    };
  },
  beforeDestroy() {
    this.$store.commit("build/setBuild", new Build());
    this.$store.commit("orderFeed/clearChanges");
  },
  computed: {
    ...mapGetters({
      build: "build/build",
    }),
    resTaxBase: {
      get() {
        return this.build.res_tax_base / 100;
      },
      set(value) {
        if (value) {
          this.$store.commit("build/setResTaxBase", value * 100);
        }
      },
    },
    exTaxBase: {
      get() {
        return this.build.ex_tax_base / 100;
      },
      set(value) {
        if (value) {
          this.$store.commit("build/setExTaxBase", value * 100);
        }
      },
    },
    taxBase: {
      get() {
        return this.build.tax_base / 100;
      },
      set(value) {
        if (value) {
          this.$store.commit("build/setTaxBase", value * 100);
        }
      },
    },
    deliveryDate: {
      get() {
        return this.build["delivery_date"];
      },
      set(value) {
        if (value) {
          this.$store.commit("build/setDeliveryDate", value);
        }
      },
    },
    countryList() {
      let countries = [...this.build.country_taxes].sort((a, b) => {
        return b.vat - a.vat;
      });
      if (this.shortCountryList) {
        return countries.splice(0, 5);
      }

      return countries;
    },
    colorName() {
      if (this.selectedColor) {
        return this.selectedColor.ms_title;
      }

      return null;
    },
    buildSubCategories() {
      if (this.build.category.id === 1) {
        return this.$store.state.build.buildTitles;
      } else {
        if (this.build.model.id !== null) {
          return this.build.model["sub_categories"];
        } else {
          return [];
        }
      }
    },
    bikeModels() {
      return this.$store.state.models.list;
    },
    modelCategories() {
      if (this.build.category.id === 1) {
        return this.$store.state.models.list;
      } else {
        return this.$store.state.build.buildCategories;
      }
    },
    showSpecList() {
      return this.build.specs !== null && this.build.category.id === 1;
    },
    buildCreatedAt() {
      return this.$moment(this.build.created_at).format(
        "MMMM Do YYYY, h:mm:ss a",
      );
    },
    creatorLink() {
      return `/customers/${this.build.creator.id}`;
    },
    previewImages() {
      return this.$store.state.build.build.media.preview
        ? [this.$store.state.build.build.media.preview]
        : [];
    },
    galleryImages() {
      return this.$store.state.build.build.media.images || [];
    },
    metaImage() {
      return this.$store.state.build.build.meta.image
        ? [this.$store.state.build.build.meta.image]
        : [];
    },
    models() {
      return this.$store.state.models.list;
    },
    sizes() {
      return this.$store.state.sizes.list;
    },
    colors() {
      return this.$store.state.colors.list;
    },
    colorGroups() {
      return this.$store.state.colors.groups;
    },
    brands() {
      return this.$store.state.brands.list;
    },
    selectedModel() {
      return this.$store.state.build.build.model;
    },
    modelCategory: {
      get() {
        return this.$store.state.build.build.model.title;
      },
      set(modelCategory) {
        this.$store.commit("build/setModel", modelCategory);
      },
    },
    buildSubCategory: {
      get() {
        return this.$store.state.build.build.build_category.title;
      },
      set(subCategory) {
        this.$store.commit("build/setBuildCategory", subCategory);
      },
    },
    size: {
      get() {
        return this.$store.state.build.build.size;
      },
      set(size) {
        this.$store.commit("build/setSize", size);
      },
    },
    color: {
      get() {
        return this.selectedColor;
      },
      set(color) {
        this.selectedColor = color;
        this.$store.commit("build/setColor", color);
      },
    },
    colorCustom: {
      get() {
        let color = this.$store.state.build.build.color;
        return color ? color.custom : null;
      },
      set(custom) {
        this.$store.commit("build/setColorCustom", custom);
      },
    },
    colorPrice() {
      if (this.selectedColor) {
        return this.selectedColor.price;
      }

      return null;
    },
    isColorCustom() {
      if (this.selectedColor) {
        return this.selectedColor.custom;
      }

      return false;
    },
    colorGroup() {
      if (this.selectedColor) {
        return this.selectedColor.group.title;
      }

      return null;
    },
    brand: {
      get() {
        return this.$store.state.build.build.brand;
      },
      set(brand) {
        this.$store.commit("build/setBrand", brand);
      },
    },
    dropzoneSettingsMultiple() {
      return {
        url: API_ENDPOINT + "v2/builds/media",
        uploadMultiple: true,
        autoProcessQueue: true,
        addRemoveLinks: true,
        maxFiles: 40,
      };
    },
    dropzoneSettings() {
      return {
        url: API_ENDPOINT + "v2/builds/media",
        uploadMultiple: false,
        autoProcessQueue: true,
        addRemoveLinks: true,
        maxFiles: 1,
      };
    },
    pageTitle() {
      return "User bike";
    },
    lessOrMore() {
      return this.shortCountryList ? "Show more" : "Show less";
    },
    filterCategories() {
      return this.$store.state.build.filterCategories;
    },
    experiences() {
      return this.$store.state.build.experiences;
    },
    wheelSizes() {
      return this.$store.state.build.wheelSizes;
    },
    motorTypes() {
      return this.$store.state.build.motorTypes;
    },
    suspensionTravel() {
      return this.$store.state.build.suspensionTravel;
    },
    bikeTypes() {
      return this.$store.state.build.bikeTypes;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    fieldWithPrefix(language, field) {
      let prefix = language.prefix;
      if (prefix === "en") {
        prefix = "";
      }
      return `${prefix + field}`;
    },
    changeAnotherPrice(base, prefix = "") {
      this.$store.commit("build/setNonEuPrice", {
        prefix: prefix,
        value: parseFloat(base),
      });
      this.$store.commit("build/setAndorraPrice", {
        prefix: prefix,
        value: parseFloat(base) + parseFloat(base / 100) * 4.5,
      });
    },
    andorraPrice(base) {
      return this.$crConverter(
        parseFloat(base) + parseFloat((base / 100) * 4.5),
      );
    },
    showMoreCountries() {
      this.shortCountryList = !this.shortCountryList;
    },
    mainPrice(vat) {
      let taxBase = this.taxBase;

      return taxBase + (taxBase * vat) / 100;
    },
    exPrice(vat) {
      let taxBase = this.exTaxBase;

      return taxBase + (taxBase * vat) / 100;
    },
    resPrice(vat) {
      let taxBase = this.resTaxBase;

      return taxBase + (taxBase * vat) / 100;
    },
    changeStock() {
      this.$store
        .dispatch("build/changeStock", {
          type: this.stockType.id,
          value: this.build.changeStock,
          id: this.build.id,
        })
        .then(result => {
          this.build.stock = result.data.stock;
        })
        .catch(error => {
          this.$root.$emit("modalShow", {
            type: "error",
            text: error,
          });
        });
    },
    init() {
      let id = this.$route.params.id;
      this.$store.dispatch("build/helper").then(() => {
        this.$store.dispatch("specs/load").then(() => {
          if (id) {
            this.$store.dispatch("build/load", id).then(result => {
              this.$store.dispatch("orderFeed/load", {
                build_id: id,
                type: "builds",
              });
              this.selectedColor = result.data.color;
              this.$store.commit("build/setSpecs", {
                specs: this.$store.state.specs.list,
                values: this.$store.state.build.build.tempSpecs,
              });
            });
          } else {
            this.$store.commit("build/setSpecs", {
              specs: this.$store.state.specs.list,
              values: null,
            });
          }
        });
      });
    },
    handleExistColorGroup(group) {
      this.$store.commit("build/setColorGroup", group);
    },
    handleExistSubCategory(buildCategory) {
      this.$store.commit("build/setBuildCategory", buildCategory);
    },
    handleExistModelCategory(modelCategory) {
      this.$store.commit("build/setModel", modelCategory);
    },
    isLanguageActive(language) {
      return language.title === this.currentLanguage.title;
    },
    languageTabClass(language) {
      return {
        active: this.isLanguageActive(language),
      };
    },
    showLanguageTab(language) {
      return this.isLanguageActive(language);
    },
    back() {
      this.$router.push("/").catch(() => {
      });
    },
    clone() {
      this.$store
        .dispatch("build/clone", this.build)
        .then(result => {
          this.$root.$emit("modalShow", {
            type: "info",
            text: "Build cloned",
          });
          this.$router.push("/builds/" + result.data.id).then(() => {
            location.reload();
          }).catch(() => {
          });
        })
        .catch(error => {
          this.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    save() {
      let b = { ...this.build };

      b.media = {
        ms_cover: b.media.ms_cover ? b.media.ms_cover.replace(process.env.VUE_APP_API_STORAGE_PATH, "") : "",
        preview: b.media.preview.replace(process.env.VUE_APP_API_STORAGE_PATH, ""),
        images: b.media.images.map(item => {
          return item.replace(process.env.VUE_APP_API_STORAGE_PATH, "");
        }),
      };

      b.meta = { ...b.meta, ...{ image: b.meta.image?.replace(process.env.VUE_APP_API_STORAGE_PATH, "") || null } };

      this.$store
        .dispatch("build/save", b)
        .then(result => {
          this.$root.$emit("modalShow", {
            type: "info",
            text:
              this.build.id === null
                ? "Build created successfully"
                : "Build saved",
          });
          if (this.build.id === null) {
            this.$router.push("/builds/" + result.data.id).then(() => {
              this.init();
            }).catch(() => {
            });
          } else {
            this.init();
          }
        })
        .catch(error => {
          this.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    switchLanguage(language) {
      this.currentLanguage = language;
    },
    writeColor(title) {
      console.log(title);
    },
    removeFromMedia(event, mutator, current) {
      let currenImages;
      let filtered;
      switch (current) {
        case "preview":
          this.$store.commit(`build/${mutator}`, null);
          break;
        case "meta":
          this.$store.commit(`build/${mutator}`, null);
          break;
        case "images":
          currenImages = this.$store.state.build.build.media.images;
          filtered = currenImages.filter(item => {
            return event !== item;
          });

          this.$store.commit(`build/${mutator}`, { images: filtered, replace: true });
          break;
      }
    },
    addToMedia(event, mutator) {
      let map = event.map(item => {
        return process.env.VUE_APP_API_STORAGE_PATH + item;
      });

      if (mutator === "addMediaImages") {
        map = {
          images: map,
          replace: false,
        };
      }

      this.$store.commit(`build/${mutator}`, map);
    },
    setColorImage(event) {
      if (event.xhr.response) {
        let response = JSON.parse(event.xhr.response);
        this.$store.commit(`build/setColorImage`, response);
      }
    },
  },
};
</script>

<style lang="scss">
.color__option {
  padding-left: 2px;
  display: flex;
}

.color__option-label {
  display: block;
}

/* +++ */
.inp-group__text {
  padding: 0 0;
  font-size: 14px;
  line-height: 22px;
  display: block;
  max-width: 400px;
}

.main__content-item .inp-group__plug {
  position: absolute;
  top: 1px;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  color: #828282;
  font-size: 14px;
  line-height: 18px;
  padding-left: 10px;

  & + input {
    padding-left: 241.5px;
  }

  @media (max-width: 370px) {
    font-size: 13px;
    top: 0;
    & + input {
      font-size: 13px;
      padding-left: 225px;
    }
  }
}

.inp-group__text.p10 {
  @media (min-width: 992px) {
    padding: 0 10px;
    max-width: 380px;
  }
}

.main__content-table {
  margin-bottom: 10px;

  * {
    box-sizing: border-box;
  }

  .tr {
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 12px;

    &.big {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 4px;
    }
  }

  .th:first-child,
  .td:first-child {
    width: 44%;
    text-align: right;
    padding: 5px 10px;
    display: block;
    color: #000;
    font-weight: 400;
    font-size: 14px;
  }

  .th,
  .td {
    width: 110px;
    padding: 5px 10px;

    margin-right: 45px;

    &:first-child {
      margin-right: 0;
    }
  }

  .td {
    input {
      min-width: 100%;
      max-width: 100%;
    }
  }

  @media (max-width: 1140px) {
    .th,
    .td {
      width: 103px;
      padding: 5px 5px;
    }
  }
  @media (max-width: 991px) {
    .th:first-child,
    .td:first-child {
      width: 200px;
      text-align: left;
    }
    .th,
    .td {
      width: 120px;
      min-width: 80px;
      padding: 5px 5px;
    }
  }
  @media (max-width: 767px) {
    overflow: auto;
    .th:first-child,
    .td:first-child {
      min-width: 100px;
      margin-right: 10px;
    }
  }
}

.main__content-item_r.group-el {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .inp-group__text {
    margin-right: 12px;
    min-width: 28px;
  }

  .inp-group.inp-group__xl {
    width: 88px;
    margin-right: 12px;
  }

  .inp-group__dropdown.inp-group__xl {
    width: 159px;
    margin-right: 12px;
  }

  .btn {
    height: 30px;
    width: 88px;
    min-width: 88px;
    padding: 0 10px;
    font-weight: bold;
    color: #1c1c1c;
  }

  @media (max-width: 991px) {
    .inp-group__textarea textarea,
    .inp-group input,
    .v-select,
    .multiselect__tags {
      min-width: auto;
    }
  }
  @media (max-width: 767px) {
    .inp-group__dropdown,
    .inp-group,
    .btn {
      margin: 4px 0;
    }
  }
}

.inp-group__textarea textarea {
  min-height: 124px;
  font-size: 14px;
  line-height: 22px;
}

.mb-2 {
  margin-bottom: 8px;
}

.main__content-item_l {
  strong {
    color: #000;
    font-size: 14px;
    display: inline-block;
  }

  &.align-self-start {
    align-self: flex-start;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.main__content {
  hr {
    border: none;
    height: 1px;
    background-color: #dfdfdf;
    max-width: 360px;
    margin: 0 auto 15px;
    @media (min-width: 992px) {
      position: relative;
      left: -100px;
    }
  }

  @media (max-width: 991px) {
    hr {
      margin-left: 0;
      max-width: 100%;
    }
    .inp-group.inp-group__xl {
      margin-right: 0;
    }
  }
}

.uploaded__img {
  border: 1px solid #979797;
  background: #f9f9f9;
  border-radius: 5px;
  display: inline-flex;

  img {
    width: 150px !important;
    height: 150px !important;
    border-radius: 5px;
    object-fit: contain;
  }
}

.trans-item {
  margin: 0 auto 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 6.5%;
  position: relative;

  &.gray .wrapper {
    background: #f9f9f9;
  }

  .wrapper {
    background: #ffffff;
    border: 1px solid #979797;
    box-sizing: border-box;
    border-radius: 5px;
    max-width: 976px;
    width: calc(100% - 48px);
    padding: 12px 0;
    margin: 0 0;

    p,
    div {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Chrome/Safari/Opera */
      -khtml-user-select: none; /* Konqueror */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
    }

    &.bg-grey {
    }
  }

  .btn-burger {
    margin-right: 0;
    padding: 20px;
    background-size: 18px;
    cursor: grab !important;
    //noinspection CssInvalidPropertyValue
    cursor: -moz-grab !important;
    //noinspection CssInvalidPropertyValue
    cursor: -webkit-grab !important;
  }

  .field__remove {
    right: 0;
    position: relative;
    margin-right: -30px;
  }

  @media (max-width: 1600px) {
    padding-right: 5.5%;
  }
  @media (max-width: 991px) {
    padding-right: 24px;
    .main__content-item_r {
      width: 100%;

      .dropzone {
        width: 100%;
        min-width: 100%;
      }
    }
  }
  @media (max-width: 575px) {
    .main__content-item_r .inp-group__textarea textarea,
    .main__content-item_r .inp-group input,
    .main__content-item_r .v-select,
    .multiselect__tags {
      min-width: 100%;
    }
    .wrapper {
      width: calc(100% - 26px);
    }
    .btn-burger {
      margin-right: 8px;
      padding: 0;
    }
  }
}

.arrow-ic {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 6px;
  transform: scale(0.7);
  opacity: 0.4;
  transition: 0.2s;
}

.dropdown-menu {
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: absolute;
  background: #fff;
  width: 100%;
  z-index: 10;
  top: calc(100% - 1px);
  border: 1px solid #b2b2b2;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  overflow: hidden;

  li {
    cursor: pointer;
    font-size: 15px;
    line-height: 1;
    padding: 12px;
    margin: 4px 0 0;

    &:hover {
      background-color: #f1f1f1;
    }
  }

  &.is-active {
    display: block;

    & ~ .arrow-ic {
      transform: rotate(180deg) scale(0.7);
    }
  }
}

.inp-group input.border-none {
  border: none;
  box-shadow: none;
  padding: 0;
}

.sortable-chosen .trans-item .wrapper {
  //background: #f9f9f9;
  position: relative;

  .dropzone {
    background-color: #fff;
  }
}

.show-more {
  display: block;
  width: 100%;
  text-align: center;

  .show-more-countries {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    display: inline-block;
    margin: 0 auto 0;
    align-items: center;
    color: #1c1c1c;
    border: none;
    border-bottom: 1px dashed;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    position: relative;
    right: 3%;
    @media (max-width: 1500px) {
      right: 2%;
    }
    @media (max-width: 991px) {
      right: 0;
    }
  }
}

hr.wcs {
  max-width: 700px;
  right: auto;
  left: auto;
  margin-left: auto;
  margin-right: calc(54% - 375px);
  display: block;
  margin-bottom: 16px;
  margin-top: 10px;
  @media (max-width: 1500px) {
    margin-right: calc(54% - 384px);
  }
  @media (max-width: 991px) {
    margin-right: auto;
    margin-left: 0;
  }
}

.eu {
  @media (max-width: 991px) {
    text-align: left !important;
    margin-left: 0 !important;
    padding: 0 10px 8px 10px;
  }
}
</style>
